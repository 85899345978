
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Communication } from '../../model/communication';
const headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
@Injectable()
export class CommunicationService<T> {

    private apiurl = 'api/communication';

    constructor(private http: HttpClient) { }

    getCommunicationByModule(module: string, enrollmentId: number) {
        return this.http.get<T>(`${this.apiurl}/find/${module}/${enrollmentId}`);
    }

    getById(id: number) {
        return this.http.get<Array<T>>(`${this.apiurl}/list/${id}`);
    }

    find(id: number) {
        return this.http.get<T>(`${this.apiurl}/find/${id}`);
    }

    save(communication: Communication) {
        if (communication.id) {
            return this.put(communication);
        }
        return this.post(communication);
    }

    private post(communication: Communication) {
        return this.http
            .post<T>(`${this.apiurl}/add`, JSON.stringify(communication), { headers });
    }

    private put(communication: Communication) {
        return this.http.put<T>(`${this.apiurl}/update/${communication.id}`, JSON.stringify(communication), { headers });
    }

}