import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Initializer, Dashboard, Graph, Receipt, Sponsor } from '../model/index';
import { DashboardService } from '../shared/service/dashboard.service';
import * as d3 from 'd3';
import { SponsorService } from '../shared';

import { trigger, state, style, transition, animate } from '@angular/animations';

//const d = require('./data.json');
//const piedate = require('./piedata.json');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [''],
  animations: [
    trigger('slidePanel', [
      state('closed', style({
        transform: 'translateX(100%)',
      })),
      state('open', style({
        transform: 'translateX(0)',
      })),
      transition('closed => open', animate('300ms ease-in')),
      transition('open => closed', animate('300ms ease-out')),
    ]),
  ],
})
export class DashboardComponent implements OnInit {

  data: Initializer;
  dashboard: Dashboard;
  effectiveDateGraph: Array<Graph>;
  expirationDateGraph: Array<Graph>;
  regionSponsors: Array<Graph>;
  centerSponsors: Array<Graph>;
  //receipts: Array<Receipt>;
  receipts: Array<any>;
  sponsors: Array<Graph>;
  contributions: Array<Graph>;

  newSponsors: Array<Sponsor>;

   // Pagination
   p: number = 1; // Current page number
   itemsPerPage: number = 10; // Number of items to display per page

  constructor(private route: ActivatedRoute, private dashboardService: DashboardService, private sponsorService: SponsorService<Sponsor>) { }

  maxOutList: any;

  ngAfterContentInit() {
    d3.select('p').style('color', 'red');
  }
  ngOnInit() {
    this.dashboard = new Dashboard()
    this.data = this.route.snapshot.data.initdata;
    this.dashboard.sponsorCount = this.data.sponsorCount;
    this.dashboard.studentCount = this.data.studentCount;
    this.dashboard.enrollmentCount = this.data.enrollmentCount;
    this.dashboard.maxOutOverviews0 = this.data.maxOutOverviews0;
    this.dashboard.maxOutOverviews1 = this.data.maxOutOverviews1;
    this.dashboard.maxOutOverviews2 = this.data.maxOutOverviews2;

    this.maxOutList = [
      { name: 'Total Past Due', data: this.dashboard.maxOutOverviews0 },
      { name: 'Total Due this month', data: this.dashboard.maxOutOverviews1 },
      { name: 'Total Due next month', data: this.dashboard.maxOutOverviews2 }
    ];

    this.getGraphDataByEffectiveDate();
    this.getGraphDataByExpirationDate();
    this.getSponsorsByDemography('region');
    this.getSponsorsByDemography('center');
    this.getReceipts();
    this.getSponsors();
    this.getContributionsAndSponsorCount();

    this.getNewSponsorsList();
  }

  panelState: 'open' | 'closed' = 'closed';

  togglePanel() {
    this.panelState = this.panelState === 'open' ? 'closed' : 'open';
  }

  getNewSponsorsList(){[
    this.sponsorService.getSponsors(100).subscribe(
      data => this.newSponsors = data,
      error => console.error('Error in getting expiration date enrollment graph')
    )
  ]}

  getGraphDataByEffectiveDate() {
    this.dashboardService.getEnrollmentByEffectiveDateGraph().subscribe(
      data => this.effectiveDateGraph = data,
      error => console.error('Error in getting effective date enrollment graph')
    );
  }

  getGraphDataByExpirationDate() {
    this.dashboardService.getEnrollmentByExpirationDateGraph().subscribe(
      data => this.expirationDateGraph = data,
      error => console.error('Error in getting expiration date enrollment graph')
    );
  }

  getSponsorsByDemography(by: string) {
    this.dashboardService.getSponsorsByDemography(by).subscribe(
      data => {
        if ('region' === by) { this.regionSponsors = data }
        else this.centerSponsors = data
      },
      error => console.error('Error in getting expiration date enrollment graph')
    );
  }
  getSponsors(){
    this.dashboardService.getSponsors().subscribe(
      data => this.sponsors = data,
      error => console.error('Error in getting sponsor graph')
    );
  }
  getReceipts() {
    this.dashboardService.getReceipts().subscribe(
      data => {
        if (data) {
          /*
          this.receipts = data.reduce((initiatives, item) => {
            initiatives[item.initiativeId] = initiatives[item.initiativeId] || []
            initiatives[item.initiativeId].push({
              yaxis: item.total,
              xaxis: item.receiptDate
            })
            return initiatives
          }, []); */
          this.receipts = data.map( item => {
            return {  yaxis : +item.total, xaxis: item.receiptDate }
          });
        }
      },
      error => console.error('Error in getting expiration date enrollment graph')
    );
  }

  getContributionsAndSponsorCount(){
    this.dashboardService.getContributionsAndSponsorCount().subscribe(
      data => this.contributions = data,
      error => console.error('Error in getting expiration date enrollment graph')
    );
  }

  sortColumn: string = '';
  sortOrder: string = 'asc';

  sort(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }

    this.newSponsors = this.newSponsors.sort((a, b) => {
      if (this.sortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
  }

  //sortedItems = this.newSponsors.slice();
}
