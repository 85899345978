import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Communication } from "../../model/communication";
import { User } from "../../model/user";

const headers = new HttpHeaders()
    .set('Content-Type', 'application/json');

@Injectable()
export class UserService {

    private api = '/api/user';

    constructor(private http: HttpClient) { }

    list() {
        return this.http.get<Array<User>>(`${this.api}/list`);
    }

    listCommunication() {
        return this.http.get<Array<Communication>>(`${this.api}/list/communications`);
    }
    
}