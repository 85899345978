import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReceiptsService } from '../../shared/service/receipts.service';
import { Parish, Receipts } from '../../model';
import { AdminService } from '../..';
import * as moment from 'moment';
//import * as FileSaver from 'file-saver';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-donation-list',
  templateUrl: './donation-list.component.html',
  styleUrls: ['./donation-list.component.css']
})
export class DonationListComponent implements OnInit {

  individualReceipts: Array<Receipts>;
  parishReceipts: Array<Receipts>;
  organizationReceipts: Array<Receipts>;
  ind:string = 'INDL';
  prh:string = 'PRH';
  org:string = 'ORG';
  displayReceiptsList: boolean;
  selection: any

  selectedElement:any;

  @ViewChild('receiptId') receiptIdElement: ElementRef;
  @ViewChild('lastName') lastNameElement: ElementRef;
  @ViewChild('firstName') firstNameElement: ElementRef;

  parishes: Array<Parish>;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private receiptsService: ReceiptsService, private adminService: AdminService<Parish>) { }

  ngOnInit() {
    this.onRageSelect(1);
    this.adminService.get('/api/admin/parishes')
    .subscribe(
      data => this.parishes = data,
      err => console.log(err)
    );
  }

 
  findReceiptById() {
    this.receiptsService.findReceipts(this.receiptIdElement.nativeElement.value)
      .subscribe(
        data => {
          this.parishReceipts = data.filter(d=> d.receiptType == 0 );
          //organization
          this.organizationReceipts = data.filter(d=> d.receiptType == 1 );
          // individual
          this.individualReceipts = data.filter(d=> d.receiptType == 2 );
        },
        err => this.handleError
      );
  }

  searchByFirstAndLastName() {
    this.receiptsService.findReceiptsByFnAndLn(
      this.firstNameElement.nativeElement.value, this.lastNameElement.nativeElement.value)
      .subscribe(
        data => {
          this.parishReceipts = data.filter(d=> d.receiptType == 0 );
          //organization
          this.organizationReceipts = data.filter(d=> d.receiptType == 1 );
          // individual
          this.individualReceipts = data.filter(d=> d.receiptType == 2 );
        },
        err => this.handleError
      );
  }
  cancelSearch() {
    this.onRageSelect(1);
    this.resetSearch();
  }

  resetSearch() {
    this.selectedElement = {id:1,Name:'Select a year'};
    this.firstNameElement.nativeElement.value = '';
    this.lastNameElement.nativeElement.value = '';
    this.receiptIdElement.nativeElement.value = '';
  }

  onParishSelect(parishId: number){
    this.receiptsService.listByParish(parishId)
    .subscribe(
      data => {
        // parish
        this.parishReceipts = data.filter(d=> d.receiptType == 0 );
        //organization
        this.organizationReceipts = data.filter(d=> d.receiptType == 1 );
        // individual
        this.individualReceipts = data.filter(d=> d.receiptType == 2 );
        /*this.individualReceipts.map(receipts=>{
         console.log(`${receipts.receiptId} ${stringSimilarity.compareTwoStrings(receipts.receiptAddress, receipts.sponsorName)}`)
        })*/
       /* this.receipts = data
        if (this.receipts.length > 0) {
          this.displayReceiptsList = true;
        } else {
          this.displayReceiptsList = false;
        }*/
      },
      err => this.handleError
  );
  }
  
  onYearSelect(year: number){
    this.receiptsService.listByYear(year)
    .subscribe(
      data => {
        this.parishReceipts = data.filter(d=> d.receiptType == 0 );
        //organization
        this.organizationReceipts = data.filter(d=> d.receiptType == 1 );
        // individual
        this.individualReceipts = data.filter(d=> d.receiptType == 2 );
      },
      err => this.handleError
    );
  }
  onRageSelect(rangeId: number){
    this.selection = rangeId;
    this.receiptsService.listByRange(rangeId)
      .subscribe(
        data => {
          // parish
          this.parishReceipts = data.filter(d=> d.receiptType == 0 );
          //organization
          this.organizationReceipts = data.filter(d=> d.receiptType == 1 );
          // individual
          this.individualReceipts = data.filter(d=> d.receiptType == 2 );
         
         /* this.receipts = data
          if (this.receipts.length > 0) {
            this.displayReceiptsList = true;
          } else {
            this.displayReceiptsList = false;
          }*/
        },
        err => this.handleError
    );
  }

  onExcelClick(){

    this.receiptsService.downloadExcel(this.selection).subscribe(
      blob => {
        importedSaveAs(blob, `Mission Board Receipts - ${moment(new Date()).format("DD/MM/YYYY")}`);
      },
      () =>{
        console.log(' Downloaded. '); 
      }
    )
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
