import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Communication } from '../feature/model/communication';
import { UserService } from '../feature/shared/service/user.service';
import { LoginService } from '../login/service/login.service';

@Component({
  selector: 'app-home',
  template: `
  <div class="container" >
      <app-header [communications]="communications" [user]="user"></app-header>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
  </div>  
  
  `
})
export class HomeComponent implements OnInit {
 
  communications: Array<Communication>;
  user: string;

  constructor(
    private router: Router,
    private authService: LoginService,
    private userService: UserService) { }

  ngOnInit(): void {
    if(!this.authService.getToken()){  
      this.router.navigate(['/login'],{ skipLocationChange: true });
    }
    this.user = this.authService.getUserDemo();
    this.userService.listCommunication().subscribe(
      data=> {
        this.communications = data
        this.sortMessage();
      },
      error => console.error('Error getting communications'+ error)
    );
  }

  sortMessage() {
    this.communications.map(communication =>{
      communication.messages.sort((m1, m2) => {
        if (m1.id > m2.id) return -1;
        if (m1.id === m2.id) return 0;
        if (m1.id < m2.id) return 1;
      });
    })
  }
}
