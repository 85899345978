import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, ElementRef } from "@angular/core";
import { Enrollment, Sponsor, Parish, Center, Student } from "../../model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SponsorService, EnrollService, AdminService, InitService, StudentService } from "../..";
import { Router } from "@angular/router";
import { ValidatorService } from "../../../shared/validator.service";
import * as moment from 'moment';
import { s } from "@angular/core/src/render3";
import { DatePipe } from "@angular/common";
import { Globals } from "../../..";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-enroll-sponsor',
  templateUrl: './enroll-sponsor.component.html',
  styleUrls: ['./enroll-sponsor.component.css']
})
export class EnrollSponsorComponent implements OnInit {

  popup = false
  name = 'Angular';

  hasAnySponsorSelected: boolean = false;
  enroll: Enrollment;
  sponsorEnrollForm: FormGroup;
  @Input() sponData;
  @Output() sponsor = new EventEmitter();
  mode: 'manual' | 'cruise' = 'manual';

  unenrolledSponsors: Array<Sponsor>;
  sponsors: Array<Sponsor>;
  parishes: Array<Parish>;
  centers: Array<Center>;
  chosenCenter: boolean;
  chosenParish: boolean;
  exitingMiscAmount:number; 
  selectedSponsor: Sponsor;
  selectedParish: number;
  public existingStudents: Array<Student>;
  public nonExistingStudents: Array<Student>;
  //editedStudentMap = {};
  editedStudents = [];

  removedStudents = [];
  userAction: string;

  enableEdit = false;
  enableEditIndex = null;
  editingStudentId: number;

  private applyMonthToAll: boolean = false;
  private applyYearToAll: boolean = false;

  @ViewChild('studentExpiration') studentExpiration: ElementRef;

  constructor(
    private studentService: StudentService,
    private sponsorService: SponsorService<Sponsor>,
    private enrollService: EnrollService,
    private initService: InitService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private adminService: AdminService<Parish>,
    private router: Router,
    private validatorService: ValidatorService,
    private globals: Globals) {

    this.createForm();
  }

  modifyDueDate(change: string, month: number, year: number){
    const orginalStudents = Object.assign([], this.existingStudents);
    // console.log(` --- ${this.applyMonthToAll} --  ${this.applyYearToAll} -- ${month} -- ${year}`)
    var dateInstance = moment({ year, month:month-1, day: 1});
    if('month' == change){
      if(this.applyMonthToAll){
        var newRenewalDate = dateInstance.format("YYYY-MM-") + dateInstance.daysInMonth();
        console.log(` --- ${newRenewalDate} `)
        this.existingStudents.map(student => {
          //console.log(` 1--- ${JSON.stringify(student)} `)
          student.maxOut=newRenewalDate;
          this.studentEditions(+student.studentCode, student.id, moment(newRenewalDate).format("MM/YYYY"), false);
        })
      }else{
        this.getStudentByEnrollmentId(this.selectedSponsor.entId);
        this.editedStudents = [];
      }
    } else if('year' == change){
      if(this.applyYearToAll){
        var newRenewalDate = dateInstance.format("YYYY-MM-DD");
       // console.log(` --- ${newRenewalDate} `)
        this.existingStudents.map(student => {
         // console.log(` 2--- ${JSON.stringify(student)} `)
          student.maxOut=newRenewalDate
          this.studentEditions(+student.studentCode, student.id, moment(newRenewalDate).format("MM/YYYY"), false);
        } )
      }else{
        this.getStudentByEnrollmentId(this.selectedSponsor.entId);
        this.editedStudents = [];
      }
    }
  }

  enableEditMethod(e, i, studentId) {
    this.enableEdit = true;
    this.enableEditIndex = i;
    this.editingStudentId = studentId;
  }

  saveSegment(studentId:number, code: number, action: boolean, student: Student){

    let expiration = !action ? this.studentExpiration.nativeElement.value : moment(student.maxOut).format("MM/y");
   
    this.studentEditions(code, studentId, expiration, action);
    
    this.existingStudents.map(st => {
      if(st.id == studentId){
        st.maxOut= moment(expiration, 'MM/YYYY').format('MM-DD-YYYY');
      }
    })
    this.resetEditAttributes();
  }

  studentEditions = (code :number, studentId: number, expiration: any, action: boolean) => {
  
   //console.log( `--- studentEditions code - ${code}, id ${studentId}, exp ${expiration} , action ${action} --`)

     this.editedStudents.push({
        canRemove: action,
        status: (!action) ? 0 : 1,
        studentCode: code,
        studentId: studentId, 
        enrollmentId:this.selectedSponsor.entId, 
        expirationMonth:  expiration.split("/")[0],
        expirationYear:  expiration.split("/")[1],
        expiration: expiration })
        if(action)
           this.existingStudents =  this.existingStudents.filter((st) =>  st.id != studentId)
    
  }

  resetEditAttributes(){
    this.enableEdit=false;
    this.editingStudentId=0;
    this.editingStudentId=0;
  }

  ngOnInit() {
    
    if (this.sponData) {
     
      this.enroll = new Enrollment(
        this.sponData.enrollmentId,
        this.sponData.sponsorId,
        this.sponData.sponsorName,
        this.sponData.effectiveDate, // paymentDate,
        this.sponData.effectiveDate,
        this.sponData.contributionAmount,
        0,
        this.sponData.sponsee);
      this.pupulateForm(this.sponData);
    } else {
      this.enroll = new Enrollment();
    }
    /*this.initService.getCenterList().subscribe(
      data => this.centers = data,
      err => console.log(err)
    );*/
    this.adminService.get('/api/admin/parishes')
      .subscribe(
        data => this.parishes = data,
        err => console.log(err)
      );
    this.chosenCenter = false;
  }


  onModeSelect(value: string) {
    if (value === 'manual') {
      this.mode= 'manual';
      this.sponsorEnrollForm.get('expirationMonth').setValidators([Validators.required, Validators.minLength(1)]);
      this.sponsorEnrollForm.get('expirationMonth').updateValueAndValidity();
      this.sponsorEnrollForm.get('expirationYear').setValidators([Validators.required, Validators.minLength(4)]);
      this.sponsorEnrollForm.get('expirationYear').updateValueAndValidity();
      this.sponsorEnrollForm.get('studentCount').setValidators([Validators.required, Validators.minLength(1)]);
      this.sponsorEnrollForm.get('studentCount').updateValueAndValidity();
    } else if (value === 'cruise') {
      this.mode= 'cruise';
      this.sponsorEnrollForm.get('expirationMonth').clearValidators();
      this.sponsorEnrollForm.get('expirationMonth').updateValueAndValidity();
      this.sponsorEnrollForm.get('expirationYear').clearValidators();
      this.sponsorEnrollForm.get('expirationYear').updateValueAndValidity();
      this.sponsorEnrollForm.get('studentCount').clearValidators();
      this.sponsorEnrollForm.get('studentCount').updateValueAndValidity();
    } else {
      console.log(' Non supporting mode selectedateInstance. ')
    }
  }

  modofyEnrollment(s: Sponsor, action: string){
    this.userAction = action;
    this.resetEditAttributes();
    this.selectedSponsor = s;
   // console.log(JSON.stringify(s));
    this.getAllStudentByEnrollmentId(s.entId);
  //  this.getStudentByEnrollmentId(s.entId);
  }
  removeChild(enrollmentId: number, studentId: number) {
    this.enrollService.releaseChild(enrollmentId, studentId)
      .subscribe(data => {
        console.log("Success");
        this.getAllStudentByEnrollmentId(enrollmentId);
      }, err => console.log(err));
  }

  deleteEnrollment(enrollmentId: number) {
    this.enrollService.release(enrollmentId)
      .subscribe(
        (data) => {
          console.log(data)
          if (data) {
            this.popup = false;
            this.editedStudents = [];
            this.getSponsorsByParishId(this.selectedParish);
          }
        },

        (error: HttpErrorResponse) => {
          console.error('HTTP Error', error.status);
          console.error('Error Details', error.message);
        })
  };

  updateEnrollment(expirationMonth:number, expirationYear: number, effectiveDate: any, amount:number, miscAmount:number, id: number){
   // console.log(` -- ${JSON.stringify(this.editedStudents)}`)  
    this.enrollService.update(id, effectiveDate,+expirationMonth, +expirationYear, amount, miscAmount , this.editedStudents).subscribe(
        data => console.log('Observer got a next value: ' + data),
        err => {
          {
            //console.log(' Clean Up');
            this.popup = false;
            this.editedStudents = [];
            this.applyYearToAll = !this.applyYearToAll;
            this.getSponsorsByParishId(this.selectedParish);
          }
        } 
      )
    //console.log(`data ${renewalDuemonth} , ${renewalDueYear} , ${effectiveDate} , ${amount} ,${id} `)
  }

  getStudentByEnrollmentId = (enrollmentId: number) => {
    this.studentService.listByEnrollmentId(enrollmentId)
    .subscribe(data => {
      this.existingStudents = data;
     
    }, err => console.log(err));
  }

  getAllStudentByEnrollmentId = (enrollmentId: number) => {
    this.studentService.listAllByEnrollmentId(enrollmentId)
    .subscribe(data => {
      this.existingStudents = data.filter(st => st.status == '0');
      this.nonExistingStudents =  data.filter(st => st.status != '0');
    }, err => console.log(err));
  }

  pupulateForm(data: any) {
    this.hasAnySponsorSelected = true;
    this.sponsorEnrollForm.setValue({
      enrollmentId: data.enrollmentId,
      sponsorId: data.sponsorId,
      parishId: data.parishId,
      sponsorName: data.sponsorName,
      contributionAmount: data.contributionAmount,
      miscAmount: data.miscAmount,
      effectiveDate: data.effectiveDate,
      studentCount: data.studentCount,
      expirationMonth: data.expirationMonth,
      expirationYear: data.expirationYear,
      sponsee: data.sponsee || []
    });
    this.onModeSelect(data.mode);
  }

  createForm() {
    this.sponsorEnrollForm = this.fb.group({
      enrollmentId: '',
      sponsorId: '',
      parishId: '',
      sponsorName: '',
      sponsee: '',
      studentCount: '',
      expirationMonth: '',
      expirationYear:  '',
      miscAmount: 0,
      contributionAmount: [null, Validators.required],
      effectiveDate: [moment(new Date()).format("MM/DD/YYYY"), [Validators.required, this.validatorService.validateDate]]
    });
  }

  selectSponsor(sponsor: Sponsor) {
    this.onModeSelect('manual');
    this.hasAnySponsorSelected = true;
    let fullName = sponsor.firstName + ' ' + sponsor.lastName;
    this.exitingMiscAmount = sponsor.miscAmount;
    this.enroll.enrollmentId = sponsor.entId;
    this.enroll.sponsorId = sponsor.id;
    this.enroll.parishId = sponsor.parishId;
    this.enroll.sponsorName = fullName;
    this.sponsorEnrollForm.controls['contributionAmount'].setValue((sponsor.amount > sponsor.netAmount) ? (sponsor.amount - sponsor.netAmount): '');
    this.sponsorEnrollForm.controls['parishId'].setValue(sponsor.parishId);
    this.sponsorEnrollForm.controls['enrollmentId'].setValue(sponsor.entId);
    this.sponsorEnrollForm.controls['effectiveDate'].setValue(this.getRenewalDate(sponsor));
    this.sponsorEnrollForm.controls['miscAmount'].setValue(sponsor.miscAmount);
    this.sponsorEnrollForm.controls['sponsorId'].setValue(sponsor.id);
    this.sponsorEnrollForm.controls['sponsorName'].setValue(fullName);
  }
  getRenewalDate(sponsor: Sponsor): string {
    if(sponsor.expirationMonth === 12){
      return '01/01/'+(sponsor.expirationYear+1)
    } else if(sponsor.noOfStudents === 0){
      return moment(new Date()).format("MM/DD/YYYY")
    }
    return (sponsor.expirationMonth+1)+'/01/'+sponsor.expirationYear
  }
  navigate() {
    const formModel = this.sponsorEnrollForm.value;
    formModel.contributionAmount = (+formModel.contributionAmount) + (+formModel.miscAmount);
    formModel.mode = this.mode;
    this.sponsor.emit(formModel);
  }
/*
  onCenterSelect(value: any) {
    if (value !== "0") {
      this.chosenCenter = true;
      this.adminService.getById('/api/admin/parishes', +value)
        .subscribe(
          data => this.parishes = data,
          err => console.log(err)
        );
    } else {
      this.chosenCenter = false;
    }
  }*/
  refreshList(){
    this.getSponsorsByParishId(+this.selectedParish);
  }

  onParishSelect(value: any) {
    this.enroll = new Enrollment();
    this.hasAnySponsorSelected = false;
    this.selectedParish = value;
    if (value !== "0") {
      this.chosenParish = true;
      this.getSponsorsByParishId(+value)
    } else {
      this.chosenParish = false;
    }
  }
  getSponsorsByParishId = (value) => {
    this.sponsorService.getSponsorsByParishId(value)
        .subscribe(data => {
          this.sponsors = data;
          this.sponsors.forEach(sponsor =>{
            sponsor.expirationMonth = +this.datePipe.transform(sponsor.maxOut, 'MM'); 
            sponsor.expirationYear = +this.datePipe.transform(sponsor.maxOut, 'yyyy'); 
            sponsor.netAmount = sponsor.netAmount - sponsor.miscAmount;
          })
          if (this.sponsors.length <= 0) {
            this.hasAnySponsorSelected = false;
          }
          this.unenrolledSponsors = this.sponsors.filter(sponsor => sponsor.noOfStudents == 0)
        },
          err => console.log(err)
        );
  }

  sortByCode() {
    this.sponsors.sort((m1, m2) => {
      if (m1.sponsorCode > m2.sponsorCode) return 1;
      if (m1.sponsorCode === m2.sponsorCode) return 0;
      if (m1.sponsorCode < m2.sponsorCode) return -1;
    });
  }
  sortByNoOfStudents(){
    this.sponsors.sort((m1, m2) => {
      if (m1.noOfStudents > m2.noOfStudents) return 1;
      if (m1.noOfStudents === m2.noOfStudents) return 0;
      if (m1.noOfStudents < m2.noOfStudents) return -1;
    });
  }
  sortByName(){
    this.sponsors.sort((m1, m2) => {
      if (m1.firstName > m2.firstName) return 1;
      if (m1.firstName === m2.firstName) return 0;
      if (m1.firstName < m2.firstName) return -1;
    });
  }
  reset() {
    console.log(' Resetting Sponsor ')
    this.hasAnySponsorSelected = false;
    this.createForm();
    this.sponsors = [];
    this.enroll = new Enrollment();
  }

  sortSponseeCode(){
    this.existingStudents.sort((m1, m2) => {
      if (m1.studentCode > m2.studentCode) return 1;
      if (m1.studentCode === m2.studentCode) return 0;
      if (m1.studentCode < m2.studentCode) return -1;
    });
  }
  sortSponseeName(){
    this.existingStudents.sort((m1, m2) => {
      if (m1.studentName > m2.studentName) return 1;
      if (m1.studentName === m2.studentName) return 0;
      if (m1.studentName < m2.studentName) return -1;
    });
  }
}
