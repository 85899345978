import { Routes } from '@angular/router';
import { EnrollmentComponent, ReleaseSponsorshipComponent } from './index';
import { ExpiredSponsorshipComponent } from './expired-sponsorship/expired-sponsorship.component';
import { ExpressCleanupComponent } from './express-cleanup/express-cleanup.component';
import { RenewalReminderComponent } from './renewal-reminder/renewal-reminder.component';

export const enrollmentRoutes: Routes = [  
    { path: 'enroll', component: EnrollmentComponent },  
    { path: 'release-sponsorship', component: ReleaseSponsorshipComponent },  
    { path: 'view-expired-sponsorship', component: ExpiredSponsorshipComponent }, 
    { path: 'express-cleanup', component: ExpressCleanupComponent }, 
    { path: 'renewal-reminder', component: RenewalReminderComponent }, 
  ];

