export class Receipts{
    sponsorReceiptId: number;
    receiptId: number;
    sponsorId: number;
    sponsorCode: string;
    rdate: string;
    title: string;
    firstName: string;
    middleName: string; 
    lastName: string;
    fullName: string;
    coSponsorName: string;
    sponsorName: string;
    lSponsorName: string;
    transaction: string;
    amount: number;
    amountInWords: string
    initiativeId: number
    initiativeName: string;
    parishName: string;
    orgName: string;
    receiptType: number;
    referenceId: number;
    city: string;
    state: string;
    zipCode: string;
    centerId: number;
    parishId: number;
    email1: string;
    email2: string;
    phone1: string;
    phone2: string;
    type: number
    createdDate: string
    createdby: number;
    updatedBy: number;
    status: number;
    streetAddress: string;
    sponsorReceiptAmount: number;
    noOfRenewal: number;
    remoteFileName: string;
    uploaded: number;
    receiptAddress: string;
    notes: string;
}