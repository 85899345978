import { Component, OnInit } from '@angular/core';
import { Sponsor } from '../model/sponsor';
import { SponsorService } from '../shared/service/sponsor.service';
import { Router } from '@angular/router';
import { Parish } from '../model';
import { AdminService } from '../shared/service/admin.service';
import { Globals } from '../..';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.css']
})
export class SponsorComponent implements OnInit {

  public sponsors: Array<Sponsor>;
  parishes: Array<Parish>;
  selectedParish: number;
  displaySponsorList: boolean = false;
  error: any;
  message: any;

  constructor(private router: Router, 
              private sponsorService: SponsorService<Sponsor>,
              private adminService: AdminService<Parish>,
              private globals: Globals) { }

  ngOnInit() {
    this.adminService.get('/api/admin/parishes')
      .subscribe(
        data => this.parishes = data,
        err => console.log(err)
      );
    this.message = 'Please select a parish to see the sponsors.'
  }

  onExcelClick(){
    //let fileName="Campaign-"+this.obj.cmpNameToSearch+"_"+this.day + "_" + this.month + "_" + this.year +".xlsx";
  
    this.sponsorService.downloadExcel(this.selectedParish).subscribe(
      blob => {
        importedSaveAs(blob, this.selectedParish.toString());
      },
      () =>{
        console.log(' Downloaded. '); 
      }
    )
  }

  onParishSelect(parishId: number) {
    if (parishId != 0) {
      this.selectedParish = parishId;
      this.message = null;
      this.sponsorService.getActiveSponsorsByParishId(parishId).subscribe(
        data => {
          this.sponsors = data
          if(this.sponsors.length>0){
            this.displaySponsorList = true;
          }else{
            this.displaySponsorList = false;
          }
        },
        err => this.handleError
       );
    } else {
      this.message = 'Please select a parish to see the sponsors.'
    }
  }

  addSponser(): void {
    this.router.navigate(['/home/sponsor/add'], { skipLocationChange: true });
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  sortByCode() {
    this.sponsors.sort((m1, m2) => {
      if (m1.sponsorCode > m2.sponsorCode) return 1;
      if (m1.sponsorCode === m2.sponsorCode) return 0;
      if (m1.sponsorCode < m2.sponsorCode) return -1;
    });
  }
  sortByFirstName() {
    this.sponsors.sort((m1, m2) => {
      if (m1.firstName > m2.firstName) return 1;
      if (m1.firstName === m2.firstName) return 0;
      if (m1.firstName < m2.firstName) return -1;
    });
  }
  sortByMI() {
    this.sponsors.sort((m1, m2) => {
      if (m1.middleInitial > m2.middleInitial) return 1;
      if (m1.middleInitial === m2.middleInitial) return 0;
      if (m1.middleInitial < m2.middleInitial) return -1;
    });
  }
  sortByLastName() {
    this.sponsors.sort((m1, m2) => {
      if (m1.lastName > m2.lastName) return 1;
      if (m1.lastName === m2.lastName) return 0;
      if (m1.lastName < m2.lastName) return -1;
    });
  }
  sortByEStatus() {
    this.sponsors.sort((m1, m2) => {
      if (m1.enrollmentStatus > m2.enrollmentStatus) return 1;
      if (m1.enrollmentStatus === m2.enrollmentStatus) return 0;
      if (m1.enrollmentStatus < m2.enrollmentStatus) return -1;
    });
  }

}
