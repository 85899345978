import { MaxOutSponsor } from "./maxoutsponsor";

export class Initializer {
    sponsorCount: number;
    studentCount: number;
    enrollmentCount: number;
    maxOutOverviews0: Array<MaxOutSponsor>;
    maxOutOverviews1: Array<MaxOutSponsor>;
    maxOutOverviews2: Array<MaxOutSponsor>;
}
