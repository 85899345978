import { Component, OnInit } from '@angular/core';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminService, InitService } from '../../../index';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Project, Center, Parish } from '../../../model/index';
import { Globals } from '../../../../globals';
import { SmartySteetsService } from '../../../shared/service/smartysteets.service';

import {Subject, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';
import { StreetSuggestion } from '../../../model/streetsuggestion';

@Component({
  selector: 'app-parish-detail',
  templateUrl: './parish-detail.component.html',
  styleUrls: ['./parish-detail.component.css']
})
export class ParishDetailComponent implements OnInit {

  pageHeader: string;
  parishId: number;
  isParishSaved: boolean;
  parishForm: FormGroup;
  addingParish = false;
  editingParish = false;
  selectedCenterId: any;
  selectedProjects: Array<number>;
  iniitalProjectList: Array<any>;
  centers: Array<Center>;

  private streetSearchTerms = new Subject<string>();
  streetSuggestions: Array<StreetSuggestion>;

  constructor(private adminService: AdminService<Parish> , private globals: Globals,
    private projectService: AdminService<Project>,
    private initService: InitService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private smartySteetsService: SmartySteetsService) {
  }

  ngOnInit() {
    this.pageHeader = 'Add new parish';
    this.selectedProjects = [];
    this.iniitalProjectList = this.route.snapshot.data.projects;
    console.log(' Resolve snapshot data ', this.iniitalProjectList);
    this.createForm();
    this.initService.getCenterList().subscribe(
      data => this.centers = data,
      err => console.log(err)
    );
    this.route.params.forEach((params: Params) => {
      if (params['id'] !== undefined) {
        this.pageHeader = 'Modify parish';
        const id = +params['id'];
        console.log('OnInit route param id ', id);
        this.populateForm(id);
      }
    });

    this.streetSearchTerms.pipe(
      debounceTime(1000), // wait for 300ms pause in events
      distinctUntilChanged(), // ignore if next search term is same as previous
      switchMap(
        term => {
          return term // switch to new observable each time
            ? // return the http search observable
            this.smartySteetsService.getStreetSuggestions(term)
            : // or the observable of empty heroes if no search term
            of<StreetSuggestion[]>([])
        }

      ),
      catchError(error => {
        // TODO: real error handling
        console.log(`Error in component ... ${error}`);
        return of<StreetSuggestion[]>([]);
      })
    ).subscribe(res =>  this.streetSuggestions = res);
  }

  setAddress(address: StreetSuggestion){
    this.parishForm.patchValue({
      pstreet: address.streetLine,
      city: address.city,
      pstate: address.state,
      ppostalCode: address.zipcode,
    })
    this.streetSuggestions = [];
  }
  createForm() {
    this.parishForm = this.fb.group({
      id: '',
      code: ['', Validators.required],
      name: ['', Validators.required],
      status: [1, Validators.required],
      centerId: ['', Validators.required],
      promoterName: ['',null],
      pphone1: [''],
      promoterEmail: ['',null],
      pstreet: '',
      city: '',
      pstate: '',
      ppostalCode: '',
      projectsList: this.buildProjects(),
      //['', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]]
    });
  }

  populateForm(parishId: number) {
    this.adminService.find('/api/admin/parishes', parishId)
      .subscribe(
        data => {
          this.editingParish = true;
          console.log(' edit - parish ', data);
          this.selectedCenterId = data.centerId;
          this.parishId = data.id;
          return this.parishForm.setValue({
            id: data.id || '',
            code: data.code || '',
            name: data.name || '',
            promoterEmail: data.promoterEmail || '',
            promoterName: data.promoterName || '',
            pstreet:data.pstreet || '',
            city:data.city || '',
            pstate:data.pstate || '',
            ppostalCode:data.ppostalCode || '',
            pphone1:data.pphone1 || '',
            status: +data.status || '',
            centerId: +data.centerId || '',
            projectsList: [this.buildProjects()]
          });
        },
        err => this.handleError);
  }

  saveParish() {
    if (this.parishForm.valid) {
      const form = Object.assign({}, this.parishForm.value, {
        projects: this.parishForm.value.projectsList.map((s, i) => {
          return {
            ppId: this.iniitalProjectList[i].ppId,
            projectId: this.iniitalProjectList[i].projectId,
            selected: s === true ? 1 : 0
          };
        }),
        projectsList: ''
      });
      this.adminService
        .save('/api/admin/parishes', form, this.parishId)
        .subscribe(
          res => {
            console.log(res);
            this.isParishSaved = true;
            this.parishId = res.id;
          },
          err => this.handleError);
    }
  }

  cancel() {
    this.router.navigate(['/home/admin/parish/list'], { skipLocationChange: true });
  }

  getStreetSuggestions(term: any){
    this.streetSearchTerms.next(term);
  }
  get projectsLists(): FormArray {
    return this.parishForm.get('projectsList') as FormArray;
  }

  buildProjects() {
    let arr = [];
    console.log(' buildform ', this.iniitalProjectList);
    if (this.iniitalProjectList) {
      arr = this.iniitalProjectList.map(s => {
        return this.fb.control(s.selected);
      });
    }
    return this.fb.array(arr);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
