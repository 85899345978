import { Message } from "./message";

type modules = "EN" | "RT";
type status = "A" | "N";
type impact = "Warn" | "Block" | "Info";
type resolution = "O" | " R"
export class Communication {
    id:number;
    sponsorCode:string;
    enrollmentId:number;
    escalationId:number;
    asignedId:number;
    parentId:number;
    receiptId:number;
    sponsorId:number;
    createdBy:number;
    updatedBy:number;
    viewed: string;
    metadata: String
    moduleName:modules;  // EN, RT
    status: status; // A -active, N - nont active
    impact: impact; //WARN BLOCK INFO
    resolution: resolution; //O Open R Resolved
    createdDate: string;
    updatedDate: string;
    firstName: string;
    lastName: string;
    message: Message;
    messages: Array<Message>;
}