import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Enrollment, Sponsee, Student } from '../../model';
import { Parish } from '../../model/parish';
import { StudentMaxOut } from '../../model/student.maxout';
import { ViewEnroll } from '../../model/viewenrollment';
import { ViewEnroll2 } from '../../model/viewenrollment2';
import { StudentService } from '../../shared';
import { AdminService } from '../../shared/service/admin.service';
import { EnrollService } from '../../shared/service/enroll.service';

@Component({
  selector: 'app-express-cleanup',
  templateUrl: './express-cleanup.component.html',
  styleUrls: ['./express-cleanup.component.css']
})
export class ExpressCleanupComponent implements OnInit {


  viewEnrolls: Array<ViewEnroll2>;
  availableStudents: Array<Student>;
  studentSearchMessage: string;
  sponsoringStudents: Array<StudentMaxOut>;
  numberOfRecords: number;
  selectedStudentMap = {};
  selectedSponsorId: number;
  sponsee: Sponsee;

  error: any;
  successmessage: any='pp';

  enroll: Enrollment = new Enrollment();

  @ViewChild('donationAmount') donationAmountElement: ElementRef;

  constructor(private enrollService: EnrollService, private studentService: StudentService) { }

  ngOnInit() {
    this.enrollService.listcleanups().subscribe(
      data => {
        this.viewEnrolls = data
        this.numberOfRecords = data.length;
      },
      err => console.log(err));
  }

  refresh() {
    this.reset();
    this.enrollService.listcleanups().subscribe(
      data => {
        this.viewEnrolls = data
        this.numberOfRecords = data.length;
      },
      err => console.log(err));
      this.successmessage='';
      this.error='';
  }

  sortByParish() {
    this.viewEnrolls.sort((m1, m2) => {
      if (m1.name > m2.name) return 1;
      if (m1.name === m2.name) return 0;
      if (m1.name < m2.name) return -1;
    });
  }
  getSponsoringChilds(sponsorId: number) {
    this.selectedSponsorId = sponsorId;
    this.enrollService.getSponsoringChilds(sponsorId).subscribe(
      data => {
        this.sponsoringStudents = data
      },
      err => console.log(err));
    this.reset();
  }
  reset() {
    this.availableStudents = [];
    this.studentSearchMessage = '';
    this.selectedStudentMap = {};
    this.successmessage='';
    this.error='';
  }
  extendEnrollment(id: number, sponsorId: number, difference: number) {
    let sponsees: Array<Sponsee> = [];

    if (this.donationAmountElement.nativeElement.value && this.donationAmountElement.nativeElement.value > 0) {
      this.enroll.enrollmentId = id;
      this.enroll.sponsorId = sponsorId;
      this.enroll.contributionAmount = this.donationAmountElement.nativeElement.value;

      Object.keys(this.selectedStudentMap).forEach(studentId => {
        const student = this.selectedStudentMap[studentId];
        sponsees.push(new Sponsee(sponsorId, student.month, 0, +studentId, ''));
      });
      this.enroll.sponsees = sponsees;
      //console.log(JSON.stringify(this.enroll));
    } else {
      //error
      console.error('Amount is not valid');
    }
    this.enrollService.save2(this.enroll).subscribe(
      data => {
        console.log('Success',  data)   
        this.successmessage='success'; 
      },
      err => this.handleError       
    )
  }
  setCheckBoxVisible(studentId: number, month: number) {
    const ele = document.getElementById(`checkBox${studentId}`) as HTMLInputElement;
    let event: any = { target: { checked: false } };

    if (month > 0) {
      ele.checked = true;
      event.target.checked = true;
      this.updateCheckedOptions(studentId, event, month);
    } else {
      document.getElementById(`checkBox${studentId}`).setAttribute("disabled", "false");
      ele.checked = false;
      event.target.checked = false;
      this.updateCheckedOptions(studentId, event, month);
    }

  }
  updateCheckedOptions(studentId, event, month) {
    this.selectedStudentMap[studentId] = { status: event.target.checked, month: month };
    //console.log(JSON.stringify(this.selectedStudentMap));
  }

  searchStudent(code: string, name: string, sponsorId: number) {
    this.studentSearchMessage = '';
    if (code) {
      this.studentService.getAvailableStudentsByProjectsAndCode(sponsorId, code).subscribe(
        data => {
          this.availableStudents = data;
          if (this.availableStudents.length == 0) {
            this.studentSearchMessage = 'No matching student available for enrollment.'
          }
        },
        err => console.log(err));
    } else {
      this.studentService.getAvailableStudentsByProjectsAndName(sponsorId, name).subscribe(
        data => {
          this.availableStudents = data;
          if (this.availableStudents.length == 0) {
            this.studentSearchMessage = 'No matching student available for enrollment.'
          }
        },
        err => console.log(err));
    }
  }

  private handleError(error: any): Promise<any> {
    this.error = error
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
